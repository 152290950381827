import * as React from "react";
import { graphql } from "gatsby";
import { Container, Row, Col, } from 'react-bootstrap';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Seo from "../components/seo";
import Layout from "../components/layout";
import MembersEntry from "../components/parts/members-entry";

import "../styles/global/base.scss";
import "../styles/global/_list.scss";
import "../styles/members.scss";

gsap.registerPlugin(ScrollTrigger);

const MembersPage = ({ data }) => {
  const [filter, setFilter] = React.useState("ALL");
  const [members, setMembers] = React.useState(data.membersJson.edges);

  const onSelectChanged = (event) => {
    setFilter(event.target.value);
  };

  React.useEffect(() => {
    if (filter === 'ALL') {
      setMembers(data.membersJson.edges);
    } else {
      const m = data.membersJson.edges.filter((edge) => edge.node.category.includes(filter));
      setMembers(m);
    }
    const t = gsap.delayedCall(0.2, ScrollTrigger.refresh);
    return (() => {
      t.kill();
    });
  }, [data.membersJson.edges, filter]);

  return (
    <Layout>
      <Seo title="MEMBERS" pageClass="membersPage" />
      <Container fluid className="listHeader">
        <Row>
          <Col xs={12} lg={4} className="pageTitle">
            <h1>MEMBERS</h1>
          </Col>
          <Col xs={12} lg={{ span: 4, offset: 4 }} className="membersFilter filters">
            <Row>
              <Col xs={12}>
                <div>
                  <h3>Category:</h3>
                  <select onChange={onSelectChanged} value={filter}>
                    <option>ALL</option>
                    <option>PRESIDENT</option>
                    <option>PRODUCTION DIV.</option>
                    <option>CREATIVE DIV.</option>
                    <option>TECH</option>
                    <option>DESK</option>
                    <option>ACCOUNTANT</option>
                  </select>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container fluid className="listContainer" id="members">
        <Row>
          {
            members.map(({ node }) => (
              <MembersEntry node={node} key={node.id} />
            ))
          }
        </Row>
      </Container>
    </Layout>
  );
};

export default MembersPage;

export const query = graphql`
  query {
    membersJson: allMembersJson {
      edges {
        node {
          category
          id
          title
          slug
          name
          imagePosition
          imagePosition_on
          image {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 960)
            }
          }
          image_on {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 960)
            }
          }
        }
      }
    }
  }
`;
