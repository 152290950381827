import * as React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import useToggleClassTrigger from "../transitions/use-toggle-class-trigger";

const MembersEntry = ({ node }) => {
  const path = `/members/${node.slug}/`;
  const image = node.image.childImageSharp.gatsbyImageData;
  const image_on = node.image_on.childImageSharp.gatsbyImageData;

  const { targetRef } = useToggleClassTrigger("entered", "top 90%");

  return (
    <Link to={path} className="list col-lg-3 col-md-4 col-sm-6 col-12 membersEntry" ref={targetRef}>
      <div className="imageOuter">
        <GatsbyImage image={image} alt="" objectPosition={node.imagePosition} />
        <GatsbyImage image={image_on} alt="" objectPosition={node.imagePosition_on} className="hoverImage" />
      </div>
      <h2>{node.name}</h2>
      <h3>{node.title}</h3>
    </Link>
  );
};

export default MembersEntry;
